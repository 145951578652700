html,
html:focus-within {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: inherit;
  -webkit-tap-highlight-color: transparent;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  display: block;
  padding-block: 1rem;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #1e1e1e;
  line-height: 1.4;
  text-rendering: optimizeSpeed;
  background: #ddddd5;
}

h1,
h2,
h3 {
  line-height: 1.1;
  margin-block: 0;
}

p {
  margin-block: 0;
  color: inherit;
}

a,
a:visited,
a:active {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
}

input,
select {
  border: none;
  background: transparent;
  font: inherit;
  color: inherit;
}

input:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input:disabled {
  cursor: not-allowed;
}

textarea {
  resize: none;
  border: none;
  font: inherit;
}

textarea:focus {
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: inline-grid;
  place-items: center;
  text-align: center;
  font: inherit;
  background-color: transparent;
  color: inherit;
}

button:disabled {
  cursor: not-allowed;
}

@media screen and (min-width: 100em) {
  html,
  html:focus-within {
    font-size: 125%;
  }
}

@media screen and (min-width: 120em) {
  html,
  html:focus-within {
    font-size: 150%;
  }
}

@media screen and (min-width: 156.25em) {
  html,
  html:focus-within {
    font-size: 180%;
  }
}

@media screen and (min-width: 200em) {
  html,
  html:focus-within {
    font-size: 300%;
  }
}

@media screen and (max-width: 38.74875em) {
  html,
  html:focus-within {
    font-size: 95%;
  }
}

@media screen and (max-width: 21.87375em) {
  html,
  html:focus-within {
    font-size: 90%;
  }
}

@media screen and (max-width: 18.75em) {
  html,
  html:focus-within {
    font-size: 85%;
  }
}
