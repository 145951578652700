.app {
  width: min(100%, 25rem);
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  min-height: calc(100dvh - 2rem);
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app.mintPage {
  background: #ffffff;
  border-radius: calc(0.5rem + 2px);
}

.jsScroll.scrolled {
  opacity: 1;
}

.scrolled.fadeInTop {
  animation: fade-in-top 1.1s ease-in-out both;
}

.scrolled.zoomIn {
  animation: zoom-in 0.8s ease-in-out both;
}

.scrolled.spreadOut {
  animation: spread-out 0.8s ease-in-out both;
}

.scrolled.slideInLeft {
  animation: slide-in-left 0.8s ease-in-out both;
}

.scrolled.slideInRight {
  animation: slide-in-right 0.8s ease-in-out both;
}

.scrolled.fadeInBottom {
  animation: fade-in-bottom 0.8s ease-in-out both;
}

.registrationForm {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  border-radius: calc(0.5rem + 2px);
}

.formControlGroup {
  overflow: hidden;
  position: relative;
}

.formControlWrapper {
  position: relative;
}

.checkIcon {
  position: absolute;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0.35rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  font-size: 1.2rem;
  color: #17a672;
  background: transparent;
}

.formControl {
  border-radius: 6px;
  height: 3.5rem;
  padding-top: calc(0.25rem + 1rem);
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  width: 100%;
  font-size: 0.95rem;
  font-weight: 500;
  border: 1px solid hsl(0, 0%, 12%, 0.55);
  transition: border 0.2s linear;
}

.formControl::placeholder {
  font-size: 0.9375rem;
  font-weight: 500;
  color: hsl(0, 0%, 12%, 0.8);
}

.formControl:focus {
  border: 1px solid hsl(0, 0%, 12%, 0.75);
  transition: border 0.2s linear;
}

.formControl.controlError {
  border-color: #cc403c;
  color: #cc403c;
}

.formControl.controlError::placeholder {
  color: #cc403c;
}

.controlLabel {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.35rem;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0.7125rem;
  color: hsl(0, 0%, 12%, 0.8);
  font-weight: 600;
  letter-spacing: -0.02em;
}

.formControl:required + .controlLabel::after {
  content: "*";
  color: #cc403c;
  font-size: 0.725rem;
  margin-left: 2px;
}

.formControlErrorMsg {
  font-size: 0.765rem;
  font-weight: 500;
  color: #cc403c;
  height: 1rem;
  margin-top: 1px;
  margin-bottom: 0.75rem;
  padding-left: 2px;
  padding-right: 2px;
}

.authenticationToggleButton {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.875rem;
  font-weight: 500;
  width: max-content;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #1e1e1e;
}

.nftImageContainer {
  min-height: 18rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  isolation: isolate;
}

.nftImageBg {
  position: absolute;
  display: block;
  width: 12.5rem;
  height: 12.5rem;
  transform: translateY(0.25rem);
  background: #a6a390;
  border-radius: 50%;
  z-index: -1;
}

.nftImageGroup {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nftImage {
  width: 16rem;
  height: auto;
  object-fit: contain;
}

.nftPatchImage {
  width: 15rem;
  position: absolute;
  z-index: -1;
  bottom: 0.25rem;
}

.founderDetailsContainer {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.founderDetailsEyebrow {
  text-transform: uppercase;
  color: #cc403c;
  font-size: 0.75rem;
  font-weight: 800;
}

.founderName {
  color: #1e1e1e;
  font-size: 1.75rem;
  font-weight: 700;
}

.founderDesignation {
  color: #1e1e1e;
  font-weight: 300;
  font-size: 1.4rem;
}

.connectDetails {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.connectDetailsEyebrow {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 500;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.connectDetailsEyebrow::after {
  content: "";
  display: block;
  height: 2px;
  background: #1e1e1e;
  width: 7.5rem;
  margin-left: 8px;
}

.connectDetailsDescription {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.connectDetailsDescriptionText {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  transform: translateY(-0.5rem);
  margin-right: 1rem;
}

.descriptionTextEyebrow {
  font-size: 0.875rem;
  font-weight: 700;
}

.descriptionText {
  font-size: 0.9375rem;
  font-weight: 700;
}

.connectDetailsIcons {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
}

.connectionIcons {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-left: 0.5rem;
}

.connectionIcons.twitterIcon {
  background: #cc403c;
}

.connectionIcons.telegramIcon {
  background: #43b0fd;
}

.connectionIcons.linkedinIcon {
  background: #edb236;
}

.connectionIcons img {
  width: 1.75rem;
}

.formSubmitButton,
.mintActionButton {
  background: #1e1e1e;
  width: calc(100% - 2rem);
  height: 3rem;
  color: #ffffff;
  font-size: 0.95rem;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin: 1rem;
  margin-top: 2rem;
  border-radius: 6px;
}

.formSubmitButton {
  margin: 0;
  margin-top: 1rem;
  width: 100%;
}

.spinnerContainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: calc(0.5rem + 2px);
}

.spinner {
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 32px 0 #000, -32px 0 #000;
  position: relative;
  animation: flash 0.75s ease-out infinite alternate;
}

.modalOverlay {
  z-index: 99;
  padding: 0 1rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: rgba(17, 17, 17, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.modalContainer {
  width: min(100%, 25rem);
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  padding: 1.5rem 2rem 1rem;
  border-radius: 0.625rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalCloseAction {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  width: 1rem;
  height: 1rem;
}

.happyNftImageContainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.happyNftImageContainer img {
  width: 14rem;
  height: 14rem;
  border-radius: 0.5625rem;
}

.congratulationsMsgContainer {
  margin-top: 0.7rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1e1e1e;
}

.congratulationsLabel {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.congratulationsMsg {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
}

.actionButtonsContainer {
  margin-top: 1.5rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButtons {
  width: calc(50% - 0.5rem);
  height: 2rem;
  background: #1e1e1e;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 0.35rem;
  border: 2px solid #1e1e1e;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twitterShareButton {
  color: #ffffff !important;
}

.twitterShareButton .twitterSVG {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.viewNftButton {
  background: transparent;
  margin-left: 1rem;
}

.explorerLinkContainer {
  margin-top: 0.65rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
}

.explorerLinkContainer .polygonScanSVG img {
  width: 1rem;
  height: 1rem;
}

.linkIconsContainer {
  margin-top: 3rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkIconsContainer img {
  width: 1.5rem;
  height: 1.5rem;
}

.smartAccountInformationContainer {
  padding: 0 2rem;
  min-height: 2.625rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.smartAccountAddress {
  font-size: 1rem;
  font-weight: 600;
  color: #1e1e1e;
  letter-spacing: 0.01em;
}

.smartAccountExplore {
  font-size: 0.85rem;
  font-weight: 500;
  border-bottom: 1px solid #1e1e1e;
  width: max-content;
}

@keyframes flash {
  0% {
    background-color: #0002;
    box-shadow: 32px 0 #0002, -32px 0 #000;
  }
  50% {
    background-color: #000;
    box-shadow: 32px 0 #0002, -32px 0 #0002;
  }
  100% {
    background-color: #0002;
    box-shadow: 32px 0 #000, -32px 0 #0002;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(35px);
    transform: translateY(35px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoom-in {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spread-out {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 15rem;
    opacity: 1;
  }
}

@media screen and (max-width: 40em) {
  .app.mintPage {
    background: transparent;
    border-radius: 0;
  }

  .registrationForm {
    background: transparent;
    border-radius: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .checkIcon {
    background: #ddddd5;
  }

  .modalContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 21.87375em) {
  .connectionIcons {
    width: 2.25rem;
    height: 2.25rem;
  }

  .connectionIcons img {
    width: 1.5rem;
  }
}
